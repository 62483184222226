import JWMLink from "@components/JWMLink/JWMLink";
import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import { useUserInfo } from "@hooks/useUserInfo/useUserInfo";
import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { Avatar } from "@jmc/solid-design-system/src/components/atoms/Avatar/Avatar";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon, jnjChevronDown, jnjChevronUp, jnjPerson } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { mdiAccount, mdiAccountOutline, mdiChevronDown, mdiCloseCircleOutline } from "@mdi/js";
import classnames from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { login, logout } from "../../api/auth";
import { useSiteStartPage } from "../../contexts/SiteContext";
import style from "./style.module.scss";

export const AccountControls = (): JSX.Element => {
    const wrapperRef = useRef(null);
    const toggleRef = useRef(null);
    const controlsRef = useRef(null);

    const [open, setOpen] = useState(false);
    const userInfo = useUserInfo();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(BreakPoint.md);
    const logoutRedirectUri = encodeURI(useSiteStartPage());
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        function handleOutsideClick(event: Event): void {
            if (
                (wrapperRef.current && !wrapperRef.current.contains(event.target)) ||
                ((event?.target as Element)?.classList?.contains(style.accountControls) && isMobile)
            ) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [wrapperRef]);

    const handleOpen = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.code == "Escape") {
            setOpen(false);
            toggleRef?.current?.firstChild?.focus();
        }
        if (event.code == "Enter") {
            setOpen(true);
            setTimeout(() => {
                controlsRef?.current?.querySelector("ul").querySelector("a")?.focus();
            }, 10);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLUListElement>): void => {
        let elem = null;
        if (event.code == "ArrowDown") {
            elem = (event?.target as Element)?.parentElement?.closest("li")?.nextElementSibling;
        }
        if (event.code == "ArrowUp") {
            elem = (event?.target as Element)?.parentElement?.closest("li")?.previousElementSibling;
        }
        if (event.code == "ArrowUp" || event.code == "ArrowDown") {
            // Avoid scroll on page
            event.preventDefault();
        }

        if (elem) {
            elem?.querySelector("a")?.focus();
        }

        handleOpen(event as unknown as React.KeyboardEvent<HTMLDivElement>);
    };

    const trackLoginButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        let clickType = "mouse";
        if (e?.clientX === 0 && e?.clientY === 0) clickType = "enter";
        analyticstracker().trackEvent({
            event: EventTypes.NAVIGATION_CLICK,
            info: {
                name: "header_navigation",
                linkname: "login",
                click_type: clickType,
            },
        });
    };

    return (
        <div className={style.accountIcon} ref={wrapperRef}>
            {!userInfo.isAuthenticated() &&
                (isMobile && jnjFullBranded ? (
                    <button
                        className={classnames(style.accountIconWrapper, jnjFullBranded ? style.mobileLogin : null)}
                        id="login"
                        aria-label={t("Sign in", { ns: "login" })}
                        onClick={(e) => {
                            trackLoginButtonClick(e);
                            login(process.env.GATSBY_COUNTRY_CODE);
                        }}
                        data-test-id="sign-in-link"
                    >
                        <Avatar className={style.avatar__container} icon={jnjPerson} iconType="jnj" size="medium" />
                    </button>
                ) : (
                    <Button
                        size={jnjFullBranded ? "small" : "medium"}
                        id="login"
                        aria-label={t("Sign in", { ns: "login" })}
                        onClick={(e) => {
                            trackLoginButtonClick(e);
                            login(process.env.GATSBY_COUNTRY_CODE);
                        }}
                        data-test-id="sign-in-link"
                        className={style.loginButton}
                        accessoryLeft={
                            jnjFullBranded ? (
                                <Icon icon={jnjPerson} color="inherit" type="jnj" size="small" verticalAlignMiddle />
                            ) : null
                        }
                        flexibleWidth={jnjFullBranded}
                    >
                        {jnjFullBranded ? null : (
                            <Icon className={style.loginIcon} icon={mdiAccountOutline} color="inherit" />
                        )}
                        {t("Sign in", { ns: "login" })}
                    </Button>
                ))}
            {userInfo.isAuthenticated() && (
                <>
                    <button
                        className={style.accountIconWrapper}
                        ref={toggleRef}
                        onClick={() => setOpen(!open)}
                        onKeyDown={handleOpen}
                        tabIndex={0}
                        data-test-id="account-dropdown"
                        aria-label={t("Profile menu", { ns: "login" })}
                    >
                        {jnjFullBranded ? (
                            <Avatar className={style.avatar__container} icon={jnjPerson} iconType="jnj" size="medium" />
                        ) : (
                            <div className={style.iconCircle} data-test-id="account-dropdown.Icon">
                                <Icon
                                    className={style.iconAccountIcon}
                                    icon={mdiAccount}
                                    color="white"
                                    size="small"
                                    verticalAlignMiddle
                                />
                            </div>
                        )}
                        <span
                            className={jnjFullBranded ? style.dropdownIndicator : null}
                            data-test-id="Select.DropdownIndicator"
                            data-test-name={`DropdownIndicator.Account`}
                        >
                            {jnjFullBranded ? (
                                <Icon
                                    icon={open ? jnjChevronUp : jnjChevronDown}
                                    color="black"
                                    size="0.75rem"
                                    type="jnj"
                                    verticalAlignMiddle
                                />
                            ) : (
                                <Icon icon={mdiChevronDown} color="text-light" verticalAlignMiddle />
                            )}
                        </span>
                    </button>
                    {open && (
                        <NavigationImpression name="user_navigation">
                            <nav className={style.accountControls} ref={controlsRef}>
                                <div className={style.accountContainer}>
                                    <div className={style.mobileControlBanner}>
                                        <div className={style.mobileLabel}>
                                            {userInfo.isAuthenticated() && (
                                                <Typography font="title" size="l" weight="600" color="inherit">
                                                    {t("Profile menu", { ns: "login" })}
                                                </Typography>
                                            )}
                                            {!userInfo.isAuthenticated() && (
                                                <Typography font="title" size="l" weight="600" color="inherit">
                                                    {t("Actions", { ns: "login" })}
                                                </Typography>
                                            )}
                                        </div>
                                        <div className={style.mobileCloseButton} id="close">
                                            <JWMLink
                                                id="user.menu.mobile.close"
                                                tabIndex={0}
                                                onClick={() => setOpen(false)}
                                            >
                                                <Icon size="large" color="inherit" icon={mdiCloseCircleOutline} />
                                            </JWMLink>
                                        </div>
                                    </div>

                                    <ul className={style.ulControlLinks} onKeyDown={handleKeyPress} role="menu">
                                        <li
                                            data-test-id="sign-out"
                                            className={classnames(style.lastItem, style.authLink)}
                                        >
                                            <JWMLink id="logout" onClick={() => logout(logoutRedirectUri)}>
                                                <div className={style.linkText}>
                                                    <Typography color="inherit" data-test-id="sign-out-link">
                                                        {t("Sign out", { ns: "login" })}
                                                    </Typography>
                                                </div>
                                            </JWMLink>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </NavigationImpression>
                    )}
                </>
            )}
        </div>
    );
};

export default AccountControls;
